/* eslint-disable jsx-a11y/iframe-has-title */
import './style.css'
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { Button } from '@mui/material';
export default function Contact(){
    
    return(
        <div className='contact' id='contact'>
           
                <form className="contactForm">
                    <div className='ContactTitle'>
                        <div className='contactBefore'></div>
                        <span className='ContactSpan'>Contact</span>
                    </div>
                    <div className='ContactTitle'>
                    <PlaceIcon sx={{color:'#002494',fontSize:{xs:'medium',lg:'large'}}}/>
                    <span className='contactDetails'>62 Kensington high street W8 4PE</span>
                    </div>
                    <div className='ContactTitle'>
                    <PhoneIcon sx={{color:'#002494',fontSize:{xs:'medium',lg:'large'}}}/>
                    <span ><a href="tel:+441212747562" className='contactDetails'>+44 121 274 7562</a></span>
                    </div>
                    <div className='ContactTitle'>
                    <ChatBubbleOutlineOutlinedIcon sx={{color:'#002494',fontSize:{xs:'medium',lg:'large'}}}/>
                    <span className='contactDetails'>MESSAGE US</span>
                    </div>
                    <input name="name" type="text" className="feedback-input" placeholder="Name" />
                    <input name="number" type="number" className="feedback-input" placeholder="Phone Number" />
                    <input name="email" type="email" className="feedback-input" placeholder="Email Adress" />
                    <textarea name="message" type="text" className="feedback-input" placeholder="Message" ></textarea>
                    <a className='footLink' href="mailto:info@moozak.co.uk">
                    <Button variant='outlined' sx={{color:'#002494',borderColor:'#002494',width:'50%',marginLeft:'25%',marginTop:2
                ,fontSize:{xs:'small',sm:'medium',lg:'large'}}}>
                        SEND Message
                        </Button>
                        </a> 
                </form>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4967.202883125901!2d-0.190535!3d51.502181!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760ff7aed27bd9%3A0xfdf3906972232f3d!2s62%20Kensington%20High%20St%2C%20London%20W8%204PE%2C%20UK!5e0!3m2!1sen!2stn!4v1715166488468!5m2!1sen!2stn" 
                 allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                 className='map'>

                </iframe>
        </div>
    )
    }