import './style.css'
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { useState } from 'react';
import { useLocation,Link } from 'react-router-dom';
export default function Footer(){
  const path = useLocation();
  const [activeLink, setActiveLink] = useState('');

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  const contact = document.getElementById('contacts');
  const about = document.getElementById('abouts');
  
  if (contact && about) {
    if (path.pathname !== 'null' && (path.pathname === '/menu' || path.pathname === '/news')) {
      contact.style.display = 'none';
      about.style.display = 'none';
    } else {
      contact.style.display = 'block';
      about.style.display = 'block';
    }
  }
    return(
        <div className='footer'>
                <div className='footerItem'>
              <h4 className='footerTitle'> VISIT MOOZAK</h4>
               <span>62 Kensington high street W8 4PE</span>
                </div>
                <div className='footerItem'>
              <h4 className='footerTitle'> Follow Us</h4>
              <div className='socialMedia'>
             <a href="https://www.tiktok.com/@moozak46" target='_blank' rel='noreferrer'>
              <img width="26" height="26" style={{borderRadius:'10px'}} src="https://img.icons8.com/ios-glyphs/28/tiktok.png" alt="tiktok"/>
              </a>
              <a href="https://instagram.com/moozaks_french_tacos?igshid=OGQ5ZDc2ODk2ZA==" target='_blank' rel='noreferrer'>
               <InstagramIcon sx={{color:'rgba(0, 0, 0, 0.80)',cursor:'pointer'}}/>
               </a>
               </div>
                </div>
                <div className='footerItem'>
              <h4 className='footerTitle'> Contact Us</h4>
              <div className='socialMedia'>
                <EmailIcon sx={{color:'rgba(0, 0, 0, 0.80)',cursor:'pointer'}}/>&nbsp;&nbsp;
               <span><a className='footLink' href="mailto:info@moozak.co.uk"> info@moozak.co.uk</a>
                    </span>
                    <span></span>
                    </div>
                </div>
                <div className='footerLink'>
                <ul className='footerNav'>
                <li ><Link to='/'   className={activeLink === 'home' ? 'navLink active' : 'footLink'}
          onClick={() => handleLinkClick('home')}>Home</Link></li>
                <li> <Link to='/menu' className={activeLink === 'menu' ? 'navLink active' : 'footLink'}
          onClick={() => handleLinkClick('menu')}>Menus</Link></li>
                 <li> <Link to='/news' className={activeLink === 'news' ? 'navLink active' : 'footLink'}
          onClick={() => handleLinkClick('news')}>News</Link></li>
            </ul>
                </div>
                <div className='footerItem'>
              <h5 className='footerTitle'>Copyright © 2023 moozak.co.uk </h5>
                </div>
        </div>
        
    )
    }